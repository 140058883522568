import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../index'
import Loader from './Loader'
import Sh from './SH'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

export const ProjectBlock = ({ inView, className, project, link }) => {

  const linkClassName = 'group block z-0 relative duration-500 ' +
    'relative hover:z-1  ' + className + (link ? ' hover:z-10 cursor-pointer' : ' cursor-default')

  return <>
    <Link to={link} className={linkClassName}>
      <div
        className={'aspect-video h-full'}>
        <div
          className={'w-full h-full bg-primary-800 overflow-hidden absolute top-0 left-0 flex items-center justify-center'}>
          {project.photo ?
            inView ? <img src={project.photo?.wide_md ?? '/placeholder.svg'}
                          className={(link ? 'group-hover:scale-[1.1]' : '') + ' duration-200 ease-in absolute flex items-center justify-center text-black ' +
                            'top-0 left-0 w-full text-transparent h-neutral-800 h-full object-cover '}
                          style={{ textIndent: -999999 }}
                          width={'650px'}
                          height={'370px'}
                          alt={project?.title ?? '-'}/> : <></>
            : <img src={'/static/placeholder.svg'} className={'w-10 mx-auto dark:invert opacity-[5%]'}
                   alt={project?.title ?? '-'}/>}
        </div>
      </div>

      <div
        className={'flex flex-wrap duration-300 px-5 left-0 pt-20 absolute bottom-0 z-10 bg-gradient-to-t rounded-b-sm from-black/70 to-transparent w-full drop-shadow py-2'}>
        <span
          className={'group-hover:text-xl duration-500 group-hover:font-black w-full drop-shadow-lg text-lg transition-all block'}>
          {project.title.split(' - ')[0]}
          <spam className={'font-thin block'}>{project.title.split(' - ')[1]}</spam>
        </span>
      </div>
    </Link>
  </>
}

export default function SectionOurWork ({ inView }) {
  const workApiLink = '/work/front'
  const [initLink, setInitLink] = useState(workApiLink)
  const [work, setWork] = useState([])
  const [workData, setWorkData] = useState(null)
  const [loading, setLoading] = useState(false)

  const workRef = useRef()
  const workRefContainer = useRef()

  useEffect(() => {
    // Load work objects
    setLoading(true)
    api.get(initLink).then(r => {

      if (!r?.data?.data) {
        return
      }

      setWork(r.data?.data)
      setWorkData(r.data?.links)

    }).finally(() => setLoading(false))

  }, [initLink])

  const nextSlide = () => {
    if (!workData.next)
      return

    const url = new URLSearchParams((new URL(workData.next)).search)
    setInitLink(workApiLink + '?page=' + url.get('page'))
  }

  const prevSlide = () => {
    if (!workData.prev)
      return

    const url = new URLSearchParams((new URL(workData.prev)).search)
    setInitLink(workApiLink + '?page=' + url.get('page'))
  }

  return <>
    <div className={'relative overflows-hidden group/mainContainer'} ref={workRef}>
      <div className={'w-full z-10 block'}>
        <div className={'mx-auto container mb-20 px-3 xl:px-0'}>
          <Sh containerThreshold={.7} parentRef={workRef}>Our work</Sh>
        </div>
        {loading
          ? <Loader/>
          : <div className={'w-full flex flex-wrap items-center'}>
            {workData?.prev && <FontAwesomeIcon
              icon={faChevronLeft}
              className={'hidden lg:block absolute cursor-pointer -bottom-20 animate-extraSlowBounce hover:scale-110 transition-all z-40 opacity-50 hover:opacity-100 text-4xl left-24'}
              onClick={prevSlide}/>}
            <div
              className={'relative container mx-auto flex items-center content-center flex-wrap md:px-0'}>
              <div className={'flex mx-auto flex-wrap z-30 lg:px-5 2xl:px-0 w-full justify-start'}
                   ref={workRefContainer}>
                {workData?.prev && <div
                  className={'lg:hidden z-10 relative w-1/2 sm:w-1/2 md:w-1/2 duration-200 h-full ease-out lg:w-1/2 3xl:w-1/3 xl:w-1/3 p-3'}>
                  <div
                    onClick={prevSlide}
                    className={'flex items-center justify-center aspect-video bg-purple-300/20 duration-500 hover:bg-black cursor-pointer rounded-sm text-8xl'}>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className={'z-10 relative w-1/2 sm:w-1/2 md:w-1/2 duration-[1600ms] ease-out lg:w-1/2 3xl:w-1/3 xl:w-1/3 p-3'}
                    />
                  </div>
                </div>}
                {work.map((w, i) =>
                  <div key={w.title}
                       className={'z-10 relative w-full sm:w-1/2 md:w-1/2 duration-[1600ms] ease-out lg:w-1/3 3xl:w-1/3 xl:w-1/3 p-3'}
                       style={{
                         transform: 'translateY(' +
                           Math.max(0,
                             Math.min(window.innerHeight, (-((window.scrollY * 7.3) - workRef.current?.getBoundingClientRect()?.top - (window.innerHeight * 20))) / ((i % 2) + 3.7))
                           )
                           + 'px)'
                       }}>
                    <ProjectBlock inView={inView} project={w} link={w.link} i={i}/>
                  </div>)}

                {workData?.next && <div
                  className={'z-10 lg:hidden relative w-1/2 sm:w-1/2 md:w-1/2 duration-200 h-full ease-out lg:w-1/2 3xl:w-1/3 xl:w-1/3 p-3'}>
                  <div
                    onClick={nextSlide}
                    className={'flex items-center justify-center aspect-video bg-purple-300/20 duration-500 hover:bg-black cursor-pointer rounded-sm text-8xl'}>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className={'z-10 relative'}
                    />
                  </div>
                </div>}
              </div>
            </div>
            {workData?.next && <FontAwesomeIcon
              icon={faChevronRight}
              className={'absolute cursor-pointer lg:block hidden animate-extraSlowBounce hover:scale-110 -bottom-20 transition-all z-30 opacity-50 hover:opacity-100 text-4xl right-24'}
              onClick={nextSlide}/>}
          </div>
        }</div>
    </div>
    <div id={'stripes'} className={'absolute w-screen !h-full z-0 top-0 left-0 opacity-[.02]'}/>
  </>
}
