import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faCircle, faEnvelope, faFax, faMapMarkerAlt, faMultiply, faPhone } from '@fortawesome/free-solid-svg-icons'
import DotMapComponent from './DotMapComponent'

export default function SectionFooter ({ showMap = true }) {

  const locations = {
    lebanon: {
      label: 'Beirut',
      address: <>7<sup>th</sup> Floor, Sasco Group Building, Beirut, Lebanon</>,
      phone: '+961 1 496286',
      fax: '',
      email: 'info@aga-adk.com'
    },
    riyadh: {
      label: 'Riyadh',
      address: <>Office 705, Al Akaria Building, 7<sup>th</sup> Floor, Olaya District, KSA</>,
      phone: '+966 11 269 1811',
      fax: '',
      email: 'info@aga-adk.com'
    },
    dubai: {
      label: 'Dubai',
      address: <>10<sup>th</sup> Floor, Fortune Executive Tower, Cluster T, Jumeirah Lakes Towers, Dubai</>,
      phone: '+971 4 445 8383',
      email: 'info@aga-adk.com'
    }
  }

  const [selectedLocation, setSelectedLocation] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const socialIconClass = 'text-3xl dark:opacity-50 hover:text-sky-200 hover:scale-110 dark:hover:opacity-100 hover:opacity-100 transition-all'

  const SocialBox = ({ className }) => <>
    <div
      className={'flex text-center justify-center xl:justify-start gap-x-5 transition-all duration-1000 items-center text-3xl'
        + (className ? ' ' + className : '')}>
      <a href={'https://www.facebook.com/AGAADK'}
         className={socialIconClass}
         rel={'noreferrer'}
         title={'Facebook'}
         target={'_blank'}>
        <FontAwesomeIcon icon={faFacebookSquare}/>
      </a>
      <FontAwesomeIcon className={'fonts-xs w-0.5 mt-1 opacity-30'} icon={faCircle}/>
      <a href={'https://www.instagram.com/agaadkmena'}
         className={socialIconClass}
         rel={'noreferrer'}
         title={'Instagram'}
         target={'_blank'}>
        <FontAwesomeIcon icon={faInstagram}/>
      </a>
      <FontAwesomeIcon className={'fonts-xs w-0.5 mt-1 opacity-30'} icon={faCircle}/>
      <a href={'https://www.linkedin.com/company/aga-adk-advertising-&-marketing/'}
         className={socialIconClass}
         rel={'noreferrer'}
         title={'LinkedIn'}
         target={'_blank'}>
        <FontAwesomeIcon icon={faLinkedinIn}/>
      </a>
      <FontAwesomeIcon className={'fonts-xs w-0.5 mt-1 opacity-30'} icon={faCircle}/>
      <a href={'https://www.youtube.com/@aga-adk1383'}
         className={socialIconClass}
         rel={'noreferrer'}
         title={'Youtube'}
         target={'_blank'}>
        <FontAwesomeIcon icon={faYoutube}/>
      </a>
    </div>
  </>

  return <div className={'text-white place-content-between text-xs relative overflow-hidden'}>
    {showMap ? <>
      <div
        onClick={() => setSelectedLocation(null)}
        className={
          (selectedLocation
            ? 'z-10'
            : 'opacity-0 z-0') +
          ' flex items-center justify-center backdrop-blur min-h-screen top-0 bg-primary-400/30 w-screen h-screen fixed overflow-hidden duration-300'}>

        {selectedLocation
          ? <div
            className={'max-w-sm md:max-w-lg w-full relative aspect-video lg:bg-white/5 lg:rounded-xl lg:shadow'}>

            <FontAwesomeIcon
              icon={faMultiply}
              className={'absolute bg-white rounded-full aspect-square text-black p-2 right-5 top-4 duration-200 cursor-pointer text-sm hover:scale-125 ' +
                'drop-shadow-sm hover:drop-shadow-md'}
              onClick={() => setSelectedLocation(null)}/>

            <div className={'p-10 flex h-full content-center items-center flex-wrap'}
                 onClick={e => e.stopPropagation()}>
              <div className={'text-sm mb-10'}>
                <h2 className={'text-xl lg:text-3xl 3xl:text-4xl select-none text-white w-full'}>
                  {selectedLocation?.label}
                </h2>
                {selectedLocation.address
                  ? <div className={'mt-5'}>
                    <FontAwesomeIcon icon={faMapMarkerAlt}
                                     className={'text-sm mb-0.5 mr-2'}/>{selectedLocation?.address}
                  </div> : <></>}
              </div>
              <section className={'text-base w-full'}>
                <div className={''}>
                  <div><FontAwesomeIcon className={'mr-2'} icon={faPhone}/>{selectedLocation?.phone}</div>
                  {selectedLocation.fax
                    ? <div><FontAwesomeIcon className={'mr-2'} icon={faFax}/>{selectedLocation?.fax}</div>
                    : <></>}
                </div>
                <a className={'underline hover:text-blue-200'}
                   href={'mailto:' + selectedLocation.email}>
                  <FontAwesomeIcon
                    className={'text-white mr-2'}
                    icon={faEnvelope}/>{selectedLocation.email}
                </a>
              </section>
            </div>
          </div>
          : <></>}

      </div>
      <div className={'relative w-full flex min-h-[calc(100vh-340px)] -left-24 md:-left-20 lg:-left-10 xl:left-0 xl:scale-110 2xl:scale-100 scale-[4] md:scale-[2.3] lg:scale-[1.5] overflow-hidden bg-primary-500'}>
        <DotMapComponent
          locations={locations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}/>
      </div>
    </> : <></>}
    <div className={'select-none text-center bg-primary-600 py-14 pb-7 px-10 relative'}>
      <p className={'text-xs xl:text-lg mb-10 font-thin'}>Member of&nbsp; <strong>THE NETWORK COMMUNICATION
        GROUP</strong></p>
      <div className={'flex sm:px-20 flex-wrap items-stretch justify-center'}>
        <div
          className={'w-full gap-y-10 flex-wrap invert max-w-7xl flex my-5 lg:items-center items-end justify-around'}>
          <><img loading={'lazy'} className={'w-full sm:max-w-[200px] h-10 inline-block 4xl:h-32'}
                           src="/static/tncg.svg"
                           alt="The Network"/>
            <img loading={'lazy'} className={'w-full md:w-min xl:h-9 h-5 4xl:h-10'} src="/static/equation.svg" alt="Equation Media"/>
            <img loading={'lazy'} className={'w-full md:w-min xl:h-10 h-10 4xl:h-12'} src="/static/pencell.svg" alt="Pencell"/>
            <img loading={'lazy'} className={'w-full md:w-min xl:h-8 h-5 4xl:h-10'} src="/static/wetpaint.svg" alt="Wetpaint"/>
          </>
        </div>
      </div>
      <div className={'mt-10 w-full flex items-center justify-center flex-wrap'}>
        <SocialBox/>
      </div>

      <div className={'mx-auto w-full py-8 opacity-20 justify-center'}>
        <div className={'w-full text-center mx-auto sm:px-20 whitespace-nowrap select-none'}>
          <span>&copy; {new Date().getFullYear()} – AGA AGK</span>
        </div>
      </div>
    </div>

  </div>
}
