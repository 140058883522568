import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpand} from "@fortawesome/free-solid-svg-icons";

export default function SectionShowreel() {
    const videoRef = useRef()

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const _ = () => {
            setScrollY(window.scrollY)
            if ((window.scrollY > window.innerHeight * 2)) {
                videoRef.current?.pause();
            } else {
                videoRef.current?.play();
            }
        }

        window.addEventListener('scroll', _)
        return () => window.removeEventListener('scroll', _)
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.src = '/static/showreel.mp4'
            const playPromise = videoRef.current?.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                }).catch(() => {
                });
            }
        }

    }, []);


    return <div className={'select-none max-h-screen w-screen z-0 overflow-hidden'}>
        <div className={'w-screen h-screen'} style={{
            scsale: Math.min(10, (((scrollY/20) - (window.innerHeight*2.2)))) + '%',
        }}>
            <video muted
                   style={{
                       filter: 'grayscale(' + Math.max(0, (-(scrollY - window.innerHeight) * .001)),
                   }}
                   ref={videoRef}
                   className={'object-cover border-y border-black h-full overflow-hidden top-0 absolute left-0 w-full'}
                   loop={true}/>
            <div className={'w-full h-screen absolute p-0'}></div>
        </div>
            <FontAwesomeIcon icon={faExpand}
                             className={'absolute bottom-10 right-10 z-20 text-2xl hover:scale-110 transition-all cursor-pointer'}
                             onClick={() => videoRef.current.requestFullscreen()}
            />
    </div>
}