"use client";
import {motion, stagger, useAnimate, useInView} from "framer-motion";
import React, {useEffect} from "react";

export const TypewriterEffect = ({words, className}) => {
    const wordsArray = words.map((word) => {
        return {
            ...word,
            text: word.text.split(""),
        };
    });

    const [scope, animate] = useAnimate();
    const isInView = useInView(scope);
    useEffect(() => {
        if (isInView) {
            animate("span", {
                display: "inline-block",
                opacity: 1,
                width: "fit-content",
            }, {
                duration: .1,
                delay: stagger(0.04),
                ease: "easeInOut",
            });
        }
    }, [isInView]);

    const renderWords = () => {
        return (
            (<motion.div ref={scope}>
                {wordsArray.map((word, idx) => {
                    return (
                        (<div key={`word-${idx}`} className="inline-block whitespace-nowrap drop-shadow-lg mr-5">
                            {word.text.map((char, index) => (
                                <motion.span
                                    initial={{}}
                                    key={`char-${index}`}
                                    className={`text-white ` + word.className}
                                    style={{opacity: .1}}>
                                    {char}
                                </motion.span>
                            ))}
                        </div>)
                    );
                })}
            </motion.div>)
        );
    };
    return <div
        className={className}>
        {renderWords()}
    </div>
};
