import React, { useEffect, useRef } from 'react'

export default function Sh ({
  parentRef,
  children,
  h2ClassName,
  spanClassName,
  gapMultiplier = 20,
  containerThreshold = .6,
  inViewThreshold = 0,
  fromColor = 'white',
  specialFirst = true,
  toColor = '#239cd0',
}) {
  const ref = useRef(null)
  const [inView, setInView] = React.useState(false)
  const [inViewSecond, setInViewSecond] = React.useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => setInView(entries[0].isIntersecting),
      { threshold: inViewThreshold }
    )

    const containerObserver = new IntersectionObserver(
      (entries) => setInViewSecond(entries[0].isIntersecting),
      { threshold: containerThreshold }
    )

    if (ref.current) observer.observe(ref.current)
    if (parentRef?.current) containerObserver.observe(parentRef.current)

    return () => {
      observer.disconnect()
      containerObserver.disconnect()
    }
  }, [inViewThreshold, containerThreshold, parentRef])

  return <>
    <span
      className={'delay-[50ms] delay-[100ms] delay-[150ms] delay-[200ms] delay-[250ms] delay-[300ms] delay-[350ms] delay-[400ms] ' +
        'delay-[450ms] delay-[500ms] delay-[550ms] delay-[600ms] delay-[650ms] delay-[700ms] delay-[750ms] delay-[800ms] delay-[850ms] ' +
        'delay-[900ms] delay-[950ms] delay-[1000ms] delay-[1050ms] delay-[1100ms] delay-[1150ms] delay-[1200ms] delay-[1250ms] delay-[1300ms] ' +
        'delay-[1350ms] delay-[1400ms] delay-[1450ms] delay-[1400ms] delay-[1450ms] delay-[1500ms] delay-[1550ms] delay-[1600ms] delay-[1650ms]'}></span>
    <h2 ref={ref}
        className={'sh overflow-hidden h-min py-2 inline-block break-all ease-out pb-6 xl:pb-2 pl-10' +
          (h2ClassName ? ' ' + h2ClassName : ' border-l-4 border-l-sky-500')} style={{ lineHeight: 0 }}>
      {children?.replace(/<\/?[^>]+(>|$)/g, '')
        .split(' ')
        .map((word, i) =>
          <span className={'drop-shadow whitespace-nowrap text-white duration-[2000ms] -mt-1 block '
            + (
              specialFirst && i > 0
                ? 'font-semibold text-4xl lg:text-6xl xl:text-7xl'
                : 'font-light text-4xl lg:text-6xl xl:text-6xl'
            ) + (spanClassName ? ' ' + spanClassName : '') +
            (inView ? ' opacity-100' : ' opacity-10')}
                style={{
                  transform: inView ? ('translateY(0px)') : ('translateY(' + (i * gapMultiplier) + '9px)'),
                  lineHeight: .7,
                }}>
            {word.split('').map((letter, a) => <>
              <span className={'duration-200 ease-in ' +
                (' delay-[' + ((i + 1) * (a + 1)) * 50 + 'ms] ')}
                    style={{
                      color: !inViewSecond ? fromColor : toColor,
                    }}>
                {letter.toString()}
              </span>
            </>)}
            &nbsp;</span>
        )}
    </h2>
  </>
}