import React, { useEffect, useRef, useState } from 'react'
import { BarLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { useSwipeable } from 'react-swipeable'

export default function MediaGallery ({ initMedia = [], setCoverImage }) {

  const [loading, setLoading] = useState(false)
  const [media, setMedia] = useState(initMedia.filter(f => !f.is_primary))
  const [selected, setSelected] = useState(initMedia.filter(f => f.is_primary)[0] ?? initMedia[0])
  const mainImageRef = useRef(null)

  const navigateGalleryItems = (direction) => {
    const currentIndex = media.indexOf(selected)
    let newIndex = currentIndex + direction

    // Handle looping
    if (newIndex < 0) {
      newIndex = media.length - 1
    } else if (newIndex >= media.length) {
      newIndex = 0
    }
    setSelected(media[newIndex])
  }

  const setMainMedia = (media) => {
    setLoading(true)
    media.type === 'photo' && setCoverImage(media)
    setSelected(media)
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          navigateGalleryItems(-1)
          break
        case 'ArrowRight':
          navigateGalleryItems(1)
          break
        default:
          return
      }
    }

    setMainMedia(selected)

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)

    }
  }, [media, selected])

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') {
        navigateGalleryItems(1)
      } else if (eventData.dir === 'Right') {
        navigateGalleryItems(-1)
      }
    }
  })

  return <>
    <div className={'w-full group relative'} ref={mainImageRef}>
      <div
        className={'mb-20 flex flex-wrap mx-auto px-6 transition-all justify-center xl:justify-start container items-center'}>
        {media.length > 1 ? media.map((media, index) => <div key={index} className={'w-1/4 sm:w-1/6 lg:w-1/6 xl:w-32'}>
          <div className={'duration-1000 ease-out drop-shadow-xl w-full p-1 aspect-video'}
               onClick={() => setSelected(media)}>

            {media.type === 'photo' ?
              <img src={media.path.square_sm}
                   tabIndex={0}
                   className={'h-full w-full object-cover duration-500 hover:ring-1 dark:ring-white block transition-all shadow hover:shadow-lg shadow-black/5 ' +
                     'hover:shadow-black/15 z-10 cursor-pointer' +
                     (media === selected ? '  !ring ' : ' rounded-sm !ring-0')}
                   style={{ textIndent: -999999 }}
                   alt={media.path.square_sm}
              />
              : <div
                className={'h-full w-full object-cover overflow-hidden duration-500 hover:ring-1 dark:ring-white block transition-all shadow hover:shadow-lg shadow-black/5 ' +
                  'hover:shadow-black/15 z-10 cursor-pointer' +
                  (media === selected ? ' !ring ' : ' rounded-sm !ring-0')}
                onClick={() => media !== selected && setSelected(media)}>
                <video
                  className={'w-full object-cover bg-black/20 flex items-center justify-center h-full cursor-pointer hover:text-white transition-all rounded-md'}
                  src={media.path?.src} autoPlay={true} loop={true} muted={true}
                >
                </video>
                <FontAwesomeIcon icon={faPlayCircle}
                                 className={'w-10 mx-auto absolute top-0 bottom-0 left-0 right-0 h-full text-white z-10'}/>
              </div>}
          </div>
        </div>) : <></>}
      </div>
      <div className={'relative'}>
        {loading
          && <div className={'absolute z-0 top-1 w-screen h-0 flex items-center justify-center'}>
            <BarLoader color={'white'}/></div>}


        <div className={'z-[50] '}
             {...handlers}>
          {selected.type === 'photo' &&
            <img src={selected?.path?.full}
                 onLoad={() => setLoading(false)}
                 alt={selected.path?.full}
                 className={'z-10 relative w-full h-full -indent-96 duration-100 '
                   + (loading ? 'blur-3xl' : '')
                 }
            />
          }
          {selected.type === 'video' && <div className={'w-screen h-screen relative'}>
            <video className={'z-10 relative w-full h-full -indent-96 duration-100 bg-black'}
                   autoPlay
                   controls={true} src={selected.path.src}/>
          </div>}

        </div>
      </div>

    </div>
  </>
}