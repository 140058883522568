import React, { useEffect, useRef, useState } from 'react'
import Sh from './SH'

export const AboutBlock = ({ containerRef, i = 0, className, offsetTop, title, children }) =>
  <div className={'4xl:leading-none p-3 sm:p-10 relative 2xl:flex  ' +
    (className ? ' ' + className : '')}
       style={{
         opacity: Math.max(5, (((window.scrollY * .5) - (window.innerHeight * .76)) * .4 + (i * 50))) + '%',
       }}
  >
    <h2
      className={'inline-block 2xl:text-4xl text-3xl 2xl:min-w-[160px] mt-0 -mb-1 2xl:mb-3 select-none font-bold'}>{title}</h2>
    <p className={'inline py-2 pl-2 text-sm sm:text-base xl:text-base 4xl:text-2xl'}>{children}
    </p>
  </div>

export default function SectionAbout ({ inView }) {
  const ref = useRef()
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const _ = () => {
      setScrollY(window.scrollY)
    }

    window.addEventListener('scroll', _)
    return () => window.removeEventListener('scroll', _)
  }, [])

  return <div className={'overflow-hidden relative xl:pb-0 '} ref={ref}>
    <div className={' opacity-80 absolute !scale-[2] mt-[130px] z-0'}
         style={{ transform: 'translateY(' + (Math.max(230, (window.scrollY - window.innerHeight) * .5)) + 'px)' }}>
      <div id="stripes"
           className={'z-20 opacity-[.10] scale-[.6] -mt-32 h-[170vh] mix-blend-luminosity invert w-screen -top-0 left-0'}/>
    </div>
    <div className={'container mx-auto'}
         style={{ transform: 'translateY(' + (Math.max(0, (window.scrollY - window.innerHeight) * .26)) + 'px)' }}>
      <Sh parentRef={ref}>About us</Sh>

      <div className={'w-full flex flex-wrap pb-[150%] sm:pb-[60vh] items-start'}>
        <div className={'xl:w-1/2  flex flex-wrap place-content-between'}
             style={{ lineHeight: Math.min(.9, Math.max(3.2, window.scrollY - window.innerHeight)) }}>
          <AboutBlock
            containerRef={ref}
            i={2}
            title={'History'}
            className={'xl:w-full w-full'}>
            Founded in 1997, we started as AGA – Arabian Gulf Advertising & Marketing, a full-service
            agency, dedicated to help
            brands create memorable campaigns in the Middle East through creative bravery, strategic
            planning, media solutions,
            PR,
            branding and physical experiences. Through perseverance and ambition, we grew out of Dubai and
            became present across
            the
            region
            in Lebanon, Jordan, Egypt and KSA. <br/><br/>Our achievements led to us signing an affiliation
            agreement with ADK,
            Japan’s
            third
            largest agency Network with 50 offices around the world, and thus our new journey as AGA-ADK
            began. <br/>
            <br/> Today, we are
            part of a
            larger holding company known as The Network Communication Group - TNCG that provides holistic
            integrated marketing
            communication solutions in the MENA region, all under one roof.
          </AboutBlock>

        </div>
        <div className={'w-full xl:w-1/2 content-between flex flex-wrap items-center justify-center'}>
          <AboutBlock
            containerRef={ref}
            i={1}
            className={'w-full'}
            title={'Vision'}>
            We strive to deliver evermore memorable campaigns to our brand partners by bringing forward
            fully integrated
            marketing communication solutions that go beyond the now.
          </AboutBlock>
          <AboutBlock
            containerRef={ref}
            i={0} className={'w-full'}
            title={'Mission'}>
            We exist to create meaningful connections between brands and consumers in an ever evolving world
            driven by mobile
            interaction and physical experiences.
          </AboutBlock>

        </div>
        {inView ?
          <video autoPlay loop src={'/static/6e64df9ce3.webm'}
                 className={'lg:hidden -mb-80 mt-40 relative w-screen'}/>
         : <></>}
      </div>
    </div>
    {inView ? <AboutBlock i={4}>
      <video autoPlay loop src={'/static/3b0c4c693.webm'}
             className={'hidden lg:block relative -mt-20 w-full'}/>
    </AboutBlock> : <></>}


  </div>
}