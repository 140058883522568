import React, { useContext } from 'react'
import SectionFooter from './SectionFooter'
import { Helmet } from 'react-helmet'
import clsx from 'clsx'
import { AppContext } from '../context/AppContext'

function PageContainer ({
  mainClassName,
  mainElement,
  defaultTitle,
  defaultDescritpion,
  children,
  heading
}) {

  const { title, setTitle, description, setDescription } = useContext(AppContext)

  return <div className={clsx('pt-20 md:pt-36 xl:pt-32', mainClassName)}>
    <div className={''}>
      <div className={'min-h-[calc(100vh-365px)]'}>
        <div>
          {heading}
        </div>
        {children}
      </div>
    </div>
    <div>
      <SectionFooter showMap={false}/>
    </div>
  </div>
}

export default PageContainer
