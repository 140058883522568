import React, { useRef } from 'react'
import Sh from './SH'
import Carousel from './Carousel'

export default function SectionClients ({ inView }) {

  const ref = useRef()

  const images = [
    { name: 'Al Fardan Exchange', thumb: '/static/c/al-fardan-exchange.svg' },
    { name: 'Anker', thumb: '/static/c/anker.svg' },
    { name: 'Always', thumb: '/static/c/always.svg' },
    { name: 'Bank of Sharjah', thumb: '/static/c/bank-of-sharjah.svg' },
    { name: 'BBAC', thumb: '/static/c/bbac.png' },
    { name: 'Bosch Logo', thumb: '/static/c/bosch.svg' },
    { name: 'Cenomi', thumb: '/static/c/cenomi.svg' },
    { name: 'DCCI', thumb: '/static/c/dcci.svg' },
    { name: 'Dunlop', thumb: '/static/c/dunlop.svg' },
    { name: 'Falken', thumb: '/static/c/falken.svg' },
    { name: 'Fidelity', thumb: '/static/c/fidelity.svg' },
    { name: 'GMG', thumb: '/static/c/gmg.svg' },
    { name: 'Hasbro', thumb: '/static/c/hasbro.svg' },
    { name: 'Iffco', thumb: '/static/c/iffco.svg' },
    { name: 'Lacnor', thumb: '/static/c/lacnor.svg' },
    { name: 'Mentos', thumb: '/static/c/mentos.svg' },
    { name: 'Michael Kors', thumb: '/static/c/michael-kors.svg' },
    { name: 'NorthwestBus', thumb: '/static/c/northwestbuse.svg' },
    { name: 'Philips', thumb: '/static/c/philips.svg' },
    { name: 'Hersheys', thumb: '/static/c/hersheys.svg' },
    { name: 'Under Armor', thumb: '/static/c/underarmor.svg' },
    { name: 'Viks', thumb: '/static/c/vicks.svg' },
    { name: 'Tabascho', thumb: '/static/c/tabasco.svg' },
    { name: 'Promarche', thumb: '/static/c/promarche.svg' },
    { name: 'Porsche', thumb: '/static/c/porsche.svg' },
    { name: 'Philip Morris', thumb: '/static/c/philip-morris.svg' },
    { name: 'Fossil Group', thumb: '/static/c/fossil-group.svg' },

  ].map((image) => ({
    id: crypto.randomUUID(),
    image
  }))

  return <div ref={ref} className={'relative container mx-auto'}>
    <div
      className={'text-white container z-10 relative mx-aut   o flex 2xl:items-start items-center flex-wrap'}>
      <Sh containerThreshold={1} parentRef={ref}>Our clients</Sh>
      <div className={'w-full lg:px-0 px-5 xl:px-10'}>
        <div className={'carousel xl:mt-20'}>
          {inView ? <Carousel images={images}/> : <></>}
        </div>
      </div>
    </div>
  </div>
}
