import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

export default function ReNoMatch () {
  return <div className={'w-screen h-screen flex items-center'}>
    <div
      className={'bg-white/5 text-white max-w-4xl p-10 shadow rounded mx-auto select-none flex flex-wrap items-center content-center justify-center'}>
      <h1 className={'font-bold text-2xl w-full text-center'}>404 - Page Not Found</h1>
      <p className={'block w-full text-center text-xs'}>The page you are looking for could not be found.</p>
      <hr className={'my-5 w-full border-white/5'}/>
      <Link className={'text-xs text-center'} to={'/'}><FontAwesomeIcon className={'mr-2'}
                                                                        icon={faHome}/>Homepage</Link>
    </div>
  </div>
}