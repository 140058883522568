import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PageContainer from '../../components/PageContainer'
import { api } from '../../index'
import { BarLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import GoBack from '../../components/GoBack'
import { AppContext } from '../../context/AppContext'
import CouldNotLoad from '../../components/CouldNotLoad'

export default function CareersSingle () {
  const { title, setTitle, description, setDescription, setCanonical } = useContext(AppContext)
  const navigate = useNavigate()

  const { id } = useParams()
  const [loading, setLoading] = React.useState(true)
  const [loadedSuccessfully, setLoadedSuccessfully] = useState(true)
  const [loadedCareer, setLoadedCareer] = useState({
    id: null,
    title: null,
    description: null,
  })

  useEffect(() => {

    setTimeout(() => {
      window.scrollTo({ top: 0 })
    }, 50)

    api.get('/careers/' + id.split('-')[0]).then(response => {
      setLoadedCareer(response?.data)

      if (response.data?.data.id_slug !== id) {
        navigate('/careers/' + response.data?.data.id_slug)
      }

      if (response.data.data.title)
        setTitle(response.data.data.title)

      if (response.data.data.description)
        setDescription(response.data.data.short_description)

      setCanonical(process.env.REACT_APP_URL + '/careers/' + response.data.data.id_slug)

      setLoadedSuccessfully(true)
    }).catch(error => {
      setLoadedSuccessfully(false)
    }).finally(() => setLoading(false))

  }, [])

  return <div className={'bg-neutral-900 text-white'}>
    {loading
      ? <div className={'relative z-10 flex items-center justify-center h-[calc(100vh-250px)]'}>
        <BarLoader color={'#09e'}/>
      </div>

      : loadedSuccessfully
        ? <PageContainer
          mainClassName={'!pt-20 md:!pt-10'}
          title={(loadedCareer.data?.title ?? '')}>
          <div className={'place-content-center container mx-auto min-h-screen'}>
            <article className={'px-5'}>

              <GoBack to={'/'}/>

              <div className={'mb-20 container mx-auto relative'}>
                <h1 className={'text-4xl 2xl:text-5xl 3xl:text-7xl transition-all p-title'}>
                  <span className={'font-bold'}>
                    {loadedCareer.data.title.split(' | ')[1]}
                  </span>
                  <span className={'font-light mx-2 lg:mx-7 duration-2000'}>|</span>
                  <strong className={'mr-6 font-light text-sky-500'}>
                    {loadedCareer.data.title.split(' | ')[0]}
                  </strong>
                </h1>
              </div>

              <div className={'text-base'} dangerouslySetInnerHTML={{ __html: loadedCareer.data.description }}/>
            </article>
          </div>
        </PageContainer> : <CouldNotLoad/>
    }
  </div>
}