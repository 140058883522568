import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

export default function CouldNotLoad () {
  return <>
    <div className={'select-none flex items-center justify-center min-h-screen bg-blue-800'}>
      <FontAwesomeIcon icon={faExclamationTriangle}/>
      <small className={'mt-1 mx-2'}>Could not load this item</small>
    </div>
  </>
}