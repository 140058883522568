import React, {useRef} from 'react';
import {TypewriterEffect} from "./typewriter-effect";

export default function Gtext({children}) {
    const ref = useRef([])

    // An independent marketing communications and creative agency

    const words = [

        {
            text: "Independent",
        },
        {
            text: "Marketing",
            className: '!text-sky-500'
        },
        {
            text: "Communications",
        },
        {
            text: "&",
            className: 'opacity-5 animate-neon font-light'
        },

        {
            text: "Creative",
            className: "opacity-5 !text-sky-500",
        },
        {
            text: "Agency",
            className: "opacity-5 !text-sky-500",
        },
    ];

    return <>
        <TypewriterEffect words={words}/>
    </>
}