import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../index'
import Sh from './SH'

export default function SectionNews ({ inView }) {

  const ref = useRef(null)
  const blocksRef = useRef([])
  const [isLoading, setIsLoading] = useState(true)
  const [loadedNews, setLoadedNews] = useState([])

  useEffect(() => {
    setIsLoading(true)
    api.get('/news/front').then(response => {
      setLoadedNews(Object.values(response.data.data))
    }).catch(error => {

    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  return <div ref={ref}
              className={'w-full pt-28 container mx-auto'}>
    <Sh containerThreshold={.3} parentRef={ref}>Latest news</Sh>
    <div>
      <div className="flex flex-wrap items-stretch">
        {loadedNews.map((n, i) =>
          <div className={'group p-5 w-full h-full md:w-1/2 xl:w-1/3 mb-10 transition-all'}>
            <Link to={'/news/' + n.id_slug}
                  className={'text-start rounded overflow-hidden inline-block hover:bg-sky-500/5 transition-all pb-10 border-b-white/10 h-full'}>
              <p className={'overflow-hidden block mb-10 w-full aspect-video'}>{
                inView ? <img
                    src={n.thumbnail
                      ? process.env.REACT_APP_API_URL + '/assets/photos/' + n.thumbnail
                      : ''}
                    width={'630px'}
                    height={'360px'}
                    alt={n.thumnail ?? n.title}
                    className={'w-full object-cover opacity-90 grayscale group-hover:grayscale-0 hover:opacity-100 transition-all bg-black/10'}/>
                  : <></>
              }
              </p>
              <div className={'flex flex-wrap w-full h-full'}>
                <div className={'w-full'}>
                  <Sh specialFirst={false} spanClassName={'inline-block !text-xl xl:!text-2xl'}>
                    {n.title}
                  </Sh>
                </div>
                <div className={'px-10 sm:px-0 w-full h-full md:mx-12 text-lg -mt-10'}
                     dangerouslySetInnerHTML={{ __html: n.short_description }}/>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  </div>
}