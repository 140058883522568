import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Sh from './SH'
import { api } from '../index'
import Loader from './Loader'

export const CareerBlock = ({ career }) => {

  const ref = useRef(null)
  return <>
    <Link ref={ref}
          to={'/careers/' + career.id_slug}
          className={'border-blue-400 bg-white/[.03] mx-5 px-6 flex items-center flex-wrap hover:dark:bg-white/10 group duration-300 h-full ' +
            'cursor-pointer group hover:shadow-xl hover:text-white dark:text-white hover:-translate-y-2 ' +
            'text-neutral-600 hover:bg-slate-800'}>

      <Sh parentRef={ref}
          specialFirst={false}
          fromColor={'#09c'}
          toColor={'white'}
          containerThreshold={1}
          spanClassName={'!text-lg !2xl:text-4xl !mb-0 text-start !text-2xl inline-block !border-l-transparent !border-l-0'}
          h2ClassName={'mb-0 !py-3 !-ml-9 border-l-none !text-start'}
          className={'font-semibold text-sm w-full '}>
        {career.title.split(' | ')[1]}
      </Sh>
    </Link>
  </>
}

export default function SectionCareers ({inView}) {
  const [careersList, setCareersList] = useState([])
  const [loading, setLoading] = useState(false)
  const ref = useRef()

  useEffect(() => {
    setLoading(true)
    api.get('/careers/front').then(r => {

      if (!r?.data?.data) {
        return
      }

      setCareersList(r.data?.data)

      if (r.data.title) {
        setCareersList(r.data?.data)
      }

      if (r.data.description) {
        //setWorkSectionDescription(r.data.description)
      }

    }).finally(() => setLoading(false))

    const updateScrollY = () => {
//            setScrollY(window.scrollY)
    }

    window.addEventListener('scroll', updateScrollY)
    return () => window.removeEventListener('scroll', updateScrollY)
  }, [])

  const bannerRef = useRef()

  return <div ref={ref} className={'flex py-40'}>
    <div className={'mx-auto w-full relative z-10'}>
      <div className={'container mx-auto'}>
        <div className={'w-full text-white'}>
          <div className={'flex flex-wrap items-center 2xl:justify-between'}>
            <div className={'xl:w-1/5'}><Sh parentRef={ref}>Join our team</Sh></div>
            <div className={'w-full xl:w-4/5'}>
              {inView ? <div className={'h-36 rounded bg-left lg:h-52 4xl:h-72 block duration-2000 ease-out'}
                   ref={bannerRef}
                   style={{
                     backgroundImage: 'url(/static/tb.webp)',
                     backgroundAttachment: '',
                     backgroundPositionY:
                       Math.max(bannerRef.current?.getBoundingClientRect().bottom - 2000,
                         bannerRef.current?.getBoundingClientRect().y - 600),
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat',
                   }}>
              </div>:<></>}
            </div>
          </div>
          <div className={'flex flex-wrap mt-32 items-center'}>
            <div className={'max-w-7xl xl:text-lg font-light px-10'}>
              Join a team where creativity knows no bounds!<br/><br/>At our agency, we're driven by bold ideas and
              diverse
              perspectives, crafting impactful solutions across industries. Here, you'll find a dynamic environment that
              values innovation, teamwork, and growth.<br/><br/> If you're ready to push the boundaries, work on
              exciting
              projects, and
              make a real impact, we'de love ot have you on board. Let's create something extraordinary together!
            </div>
          </div>
        </div>

        <div className={'px-5 mt-24'}>
          <hr className={'my-16 border-transparent'}/>
            <h3 className={'w-full ml-5 mb-5 text-3xl font-thin'}>Available Vacancies</h3>
          <div
            className={'items-stretch flex flex-wrap'}>
            {
              loading
                ? <Loader/>
                : careersList?.map(career =>
                  <div key={career.title + career.description}
                       className={'w-full my-1 lg:w-1/4'}>
                    <CareerBlock ik={career.title} career={career}/>
                  </div>)
            }
          </div>
        </div>
      </div>
    </div>
    <div id={'stripes'}
         className={'absolute w-[calc(100vw-11px)] !h-full z-0 top-0 left-0 mix-blend-overlay opacity-[.2]'}/>
  </div>
}
