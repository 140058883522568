import React, { useContext, useEffect, useRef, useState } from 'react'
import PageContainer from '../../components/PageContainer'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import GoBack from '../../components/GoBack'
import { AppContext } from '../../context/AppContext'
import CouldNotLoad from '../../components/CouldNotLoad'

export default function NewsSingle ({ title }) {
  const ref = useRef()
  const [isLoading, setIsLoading] = useState(true)
  const { slug } = useParams()
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const {
    title: contextTitle,
    setTitle,
    description: contextDescription,
    setDescription,
    setCanonical
  } = useContext(AppContext)

  const [loadedArticle, setLoadedArticle] = useState({
    id: null, title: null, body: null,
  })

  useEffect(() => {
    if (!slug) return

    api.get('/news/' + slug.split('-')[0])
      .then(response => {

        if (response.data?.data.id_slug !== slug) {
          navigate('/news/' + response.data?.data.id_slug)
        }

        setCanonical(process.env.REACT_APP_URL + '/news/' + response.data.data.id_slug)

        response && setLoadedArticle(response.data?.data)
      }).catch(e => setError(e.message))
      .finally(() => setIsLoading(false))
    setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth' }), 100)
  }, [])

  useEffect(() => {
    setTitle(loadedArticle.title)
    setDescription(loadedArticle.short_description)
  }, [loadedArticle])
  return <div ref={ref} className={''}>
    {isLoading ? <div
      className={'gap-x-10 font-thin text-3xl h-[80vh] select-none animate-pulse w-screen flex items-center justify-center'}>
      <FontAwesomeIcon className={''} icon={faSpinner} spin={true}/>
      <span>Loading article...</span>
    </div> : <div className={'bg-sky-950/80 backdrop-blur-2xl min-h-screen'}>
      {!error ? <PageContainer title={contextTitle ?? 'Loading article'} descritpion={loadedArticle.short_description}>
        <div className={'container p-5 mx-auto py-[10vh]'}>
          <GoBack to={'/'}/>
          <div className={'mb-20'}>
            <div className={'mb-20'}>
              <h1
                className={'4xl:text-9xl 3xl:text-8xl 2xl:text-7xl text-5xl font-bold drop-shadow-[0_0_0_#fff]'}>
                {contextTitle}
              </h1>
              <small
                className={'ml-1 font-serif text-sm mb-5 inline-block'}>{(new Date(loadedArticle.created_at)).toLocaleDateString()}</small>
            </div>
            <article className={'selection:bg-black text-lg'}
                     dangerouslySetInnerHTML={{ __html: loadedArticle.body }}/>
          </div>
        </div>
      </PageContainer> : <CouldNotLoad/>}
    </div>}
  </div>
}