import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { api } from '../../index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import PageContainer from '../../components/PageContainer'
import MediaGallery from '../../components/MediaGallery'
import { BarLoader } from 'react-spinners'
import GoBack from '../../components/GoBack'
import defaultTheme from 'tailwindcss/defaultTheme'
import { AppContext } from '../../context/AppContext'
import CouldNotLoad from '../../components/CouldNotLoad'
import { Helmet } from 'react-helmet'

export default function WorkSingle () {
  const navigate = useNavigate()
  const { title, setTitle, description, setDescription, canonical, setCanonical } = useContext(AppContext)
  const containerRef = useRef()
  const { id } = useParams()

  const [loadedProject, setLoadedProject] = React.useState({
    id: null,
    title: null,
    content: <></>,
    media: [],
  })

  const [coverImage, setCoverImage] = useState({})
  const [loadedSuccessfully, setLoadedSuccessfully] = useState(true)
  const [loading, setLoading] = React.useState(true)

  const [scrollY, setScrollY] = useState(window.scrollY)

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10)
    const _ = () => {
      setScrollY(window.scrollY)
    }
    window.addEventListener('scroll', _)
    window.addEventListener('resize', _)
    return () => {
      window.removeEventListener('scroll', _)
      window.removeEventListener('resize', _)
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    api.get('/work/front/' + id.split('-')[0]).then(response => {

      if (response.data.data) {

        setLoadedProject(response.data.data)

        if (response.data?.data.id_slug !== id) {
          navigate('/work/' + response.data?.data.id_slug)
        }

        if (response.data.data.title)
          setTitle(response.data.data.title)

        if (response.data.data.meta_description)
          setDescription(response.data.data.meta_description)

        setCanonical(process.env.REACT_APP_URL + '/work/' + response.data.data.id_slug)

        const media = Object.values(response.data.data.media)
        const coverPhoto = media.filter(m => m.type !== 'video')

        if (response.data.data.media) {
          setTimeout(() => {
            setCoverImage(
              coverPhoto.filter(o => o.is_primary)[0] ?? coverPhoto[0]
            )
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }, 100)
        }
        setLoadedSuccessfully(true)
      } else {
        setLoadedSuccessfully(false)
      }
    }).catch(e => {
      setLoadedSuccessfully(false)
    }).finally(() => setLoading(false))

  }, [id])

  return <div className={'relative bg-black -mt-52 pt-60 max-w-screen overflow-hidden'}>
    {loading
      ? <div className={'relative z-10 flex items-center justify-center h-[calc(100vh-250px)]'}>
        <BarLoader color={'#09e'}/>
      </div>

      : loadedSuccessfully
        ? <PageContainer title={loadedProject.title}>
          {coverImage ? <img src={coverImage?.path?.full}
                             onLoad={() => setTimeout(() => setLoading(false), 100)}
                             alt={'.'}
                             className={'blur-md z-0 h-screen top-0 fixed scale-[1.5] object-cover w-screen duration-2000 ' +
                               'ease-out blur-smd opacity-60 -indent-96 text-transparent'}
                             style={{}}/> : <></>}

          <div
            className={'select-none mt-32 container duration-[1300ms] ease-out mx-auto pl-10 xl:pl-5 hover:pl-7 hover:4xl:pl-5 z-30 relative'}>
            <GoBack/>
          </div>

          <div className={'container mx-auto z-10 relative mix-blend-color-dodge'}
               ref={containerRef}>
            <div className={'container duration-[1300ms] ease-out mx-auto px-5 pr-10'}>

              <h1 className={'pr-5 4xl:text-9xl 3xl:text-8xl 2xl:text-7xl text-5xl font-bold drop-shadow-[0_0_0_#fff]'}>
                {loadedProject.title}
              </h1>
            </div>

          </div>

          <div className={'container mx-auto'}>
            <div
              className={'overflow-hidden 3xl:max-w-6xl 4xl:max-w-7xl mt-2.5 xl:px-[18px] duration-[1200ms] ease-out relative z-10'}
            >
              <article className={'text-lg relative px-3 z-20 text-white'}
                       dangerouslySetInnerHTML={{ __html: loadedProject.content }}/>
            </div>
            {loadedProject.next ?
              <Link to={'/work/' + loadedProject.next.link}
                    style={{
                      transform: parseInt(defaultTheme.screens.xl) < window.innerWidth
                        ? 'translateY(' + Math.max(0, (
                        window.innerWidth >= parseInt(defaultTheme.screens.xl)
                          ? (window.scrollY * .23) - (window.innerHeight / 200)
                          : (window.scrollY * .47) - (window.innerHeight / 200))
                      ) + 'px)'
                        : 'translateY(0)',
                    }}
                    className={'select-none duration-[1200ms] ease-out inline-block xl:float-right text-xl xl:text-4xl ' +
                      'font-thin transition-all relative text-center w-full xl:w-fit mb-10 xl:-mb-8 z-[12] group'}>
                <div className={'xl:animate-pulse group-hover:animate-none'}>
                  Next Project
                  <FontAwesomeIcon
                    className={'text-base ml-1 xl:ml-0 xl:text-4xl duration-300 group-hover:-mr-2 group-hover:ml-2'}
                    icon={faAngleDoubleRight}/>
                  <small className={'text-xs xl:text-end block font-bold'}>{loadedProject.next.title}</small>
                </div>
              </Link> : <></>}
          </div>

          <div className={'overflow-hidden w-screen z-10 relative'}>
            <div className={'relative z-10 select-none'}>
              {loadedProject.media?.length ?
                <MediaGallery setCoverImage={setCoverImage}
                              initMedia={loadedProject.media}
                /> : <></>
              }
            </div>
          </div>
        </PageContainer>
        : <CouldNotLoad/>
    }
  </div>
}