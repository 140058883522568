import React, { useContext, useEffect, useRef, useState } from 'react'
import SectionOurWork from '../components/SectionOurWork'
import SectionCareers from '../components/SectionCareers'
import SectionIntroduction from '../components/SectionIntroduction'
import SectionClients from '../components/SectionClients'
import { AppContext } from '../context/AppContext'
import SectionNews from '../components/SectionNews'
import SectionShowreel from '../components/SectionShowreel'
import SectionAbout from '../components/SectionAbout'
import { Helmet } from 'react-helmet'
import SectionTeam from '../components/SectionTeam'
import SectionFooter from '../components/SectionFooter'
import clsx from 'clsx'

export default function App () {

  const { setShowNavbar, showNavbar, setCanonical } = useContext(AppContext)
  const ref = useRef(null)
  const aboutRef = useRef(null)
  const clientRef = useRef(null)
  const careerRef = useRef(null)
  const footerRef = useRef(null)
  const newsRef = useRef(null)
  const videoRef = useRef(null)
  const workRef = useRef(null)
  const teamRef = useRef(null)
  const [aboutInView, setAboutClientsInView] = useState(null)
  const [ourClientsInView, setOurClientsInView] = useState(null)
  const [newsInView, setNewsInView] = useState(null)
  const [teamInView, setTeamInView] = useState(null)
  const [careerInView, setCareerInView] = useState(null)
  const [workInView, setWorkInView] = useState(null)
  const [footerInView, setFooterInView] = useState(null)

  useEffect(() => {
    if (window.scrollY > videoRef.current?.clientHeight) {
      setShowNavbar(true)
    }
  }, [setShowNavbar])

  useEffect(() => {

    setCanonical(process.env.REACT_APP_URL)

    const aboutObserver = new IntersectionObserver(
      (entries) => setAboutClientsInView(entries[0].isIntersecting),
      { threshold: 0 }
    )

    const observer = new IntersectionObserver(
      (entries) => setOurClientsInView(entries[0].isIntersecting),
      { threshold: 0 }
    )

    const newsObserver = new IntersectionObserver(
      (entries) => setNewsInView(entries[0].isIntersecting),
      { threshold: 0 }
    )

    const teamObserver = new IntersectionObserver(
      (entries) => setTeamInView(entries[0].isIntersecting),
      { threshold: 0 }
    )

    const footerObserver = new IntersectionObserver(
      (entries) => setFooterInView(entries[0].isIntersecting),
      { threshold: 0 }
    )

    const workObserver = new IntersectionObserver(
      (entries) => setWorkInView(entries[0].isIntersecting),
      { threshold: 0 }
    )

    if (clientRef.current) observer.observe(clientRef.current)
    if (aboutRef.current) aboutObserver.observe(aboutRef.current)
    if (newsRef.current) newsObserver.observe(newsRef.current)
    if (teamRef.current) teamObserver.observe(teamRef.current)
    if (workRef.current) workObserver.observe(workRef.current)
    if (footerRef.current) footerObserver.observe(footerRef.current)

  }, [])
  return <>

    <Helmet>
      <title>{process.env.REACT_APP_TITLE}</title>
      <meta name={'description'} content={process.env.REACT_APP_DESCRIPTION}/>
    </Helmet>

    <div ref={ref} className={'bg-black overflow-x-hidden'}>

      <div id="introduction"
           className={'relative min-h-screen bg-primary-500'}>
        <SectionIntroduction/>
      </div>

      <div ref={videoRef} id={'showreel'} className={'bg-black relative z-50 min-h-screen max-h-screen'}>
        <SectionShowreel/>
      </div>

      <div id="about"
           ref={aboutRef}
           className={'relative min-h-screen bg-primary-600 z-20'}>
        <SectionAbout inView={aboutInView}/>
      </div>

      <div id="work"
           ref={workRef}
           className={'min-h-screen bg-primary-500 text-white py-40 overflow-hidden relative z-30'}>
        <SectionOurWork inView={workInView} _ref={ref}/>
      </div>

      <div id="clients"
           ref={clientRef}
           className={clsx('relative py-20 bg-primary-500')}>
        <SectionClients inView={ourClientsInView}/>
      </div>

      <div id="team"
           ref={teamRef}
           className={'bg-primary-500 relative z-10'}>
        <SectionTeam/>
      </div>

      <div id="news"
           ref={newsRef}
           className={'z-10 relative min-h-screen bg-primary-400'}>
        <SectionNews inView={newsInView}/>
      </div>

      <div id="careers"
           className={'bg-primary-900 relative z-10'}>
        <SectionCareers/>
      </div>

      <div ref={footerRef}>
        <SectionFooter/>
      </div>

    </div>
  </>
}