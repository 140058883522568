import React, { useEffect, useMemo, useRef, useState } from 'react'
import { faBars, faFileContract, faHome, faMultiply } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Transition } from '@headlessui/react'
import { faFacebookSquare, faInstagram, faLinkedin, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom'
import defaultTheme from 'tailwindcss/defaultTheme'

export default function Navbar () {

  const sidebarRef = useRef(null)
  const topbarRef = useRef(null)
  const navRef = useRef(null)
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const [navBG, setNavBG] = useState(null)
  const [navSize, setNavSize] = useState(null)
  const [scrollDirection, setScrollDirection] = useState('up')
  const [prevScrollPos, setPrevScrollPos] = useState(null)

  const NavButton = useMemo(() => ({ className, title, href, onClick }) => {

    const linkClass = 'duration-1000 cursor-pointer hover:bg-sky-600 rounded dark:hover:text-white ' +
      'py-2 hover:border-b-sky-500 3xl:mx-2 hover:text-black inline-block duration-300 ' +
      'px-3 3xl:px-5 text-center group relative '

    return <>
      {href ? <Link to={href} className={linkClass} onClick={onClick}>
        <span className={'' + (className ? ' ' + className : '')}>{title}</span>
      </Link> : <span className={linkClass} onClick={onClick}>{title}</span>}
    </>
  }, [])

  useEffect(() => {

    const toggleNavbar = () => {
      const currentScrollPos = window.scrollY
      const direction = currentScrollPos > prevScrollPos ? 'down' : 'up'
      setScrollDirection(direction)
      setPrevScrollPos(currentScrollPos)
    }

    window.addEventListener('scroll', toggleNavbar)
    const checkScreen = () => {
      if (window.innerWidth > parseInt(defaultTheme.screens.lg)) {
        setSidebarOpened(false)
      }

    }

    window.addEventListener('click', handleWindowOnClickEvent)
    window.addEventListener('resize', checkScreen)

    return () => {
      window.removeEventListener('resize', checkScreen)
      window.removeEventListener('click', handleWindowOnClickEvent)
      window.removeEventListener('scroll', toggleNavbar)
    }

  }, [prevScrollPos])

  const handleWindowOnClickEvent = (e) => {

    if (
      sidebarRef.current && topbarRef.current &&
      (!sidebarRef.current?.contains(e.target) && !topbarRef?.current?.contains(e.target))
    ) {
      setSidebarOpened(false)
    }

  }

  const SidebarMenuLink = useMemo(() => ({ target, className, text, href }) =>
    <>
      <Link
        className={'hover:scale-110 mx-auto py-2 block font-light justify-center ' +
          'hover:bg-sky-700 text-neutral-100 hover:text-white ' +
          'duration-300 hover:rounded-sm w-full text-center hover:fonts-black' +
          (className ? ' ' + className : '')}
        to={href ?? '#'} onClick={() =>
        setSidebarOpened(false) || document.getElementById(target)?.scrollIntoView({ behavior: 'smooth' })}>
        {text}
      </Link>
    </>, [])

  return <div ref={topbarRef}>
    <div
      className={'fixed mix-blend-difference top-0 left-0 w-screen duration-1000 z-50 text-white '
        + ' ' + navBG +
        (scrollDirection === 'up' ? ' -top-2 delay-200 ' : ' !-top-20')}>
      <nav className={'mt-2 px-5 flex items-center group justify-between'}>
        <div className={'flex items-center justify-start xl:h-20 h-14 px-5'} ref={navRef}>
          <Link to={'/'}
                className={'relative z-[101] invert font-black text-2xl select-none cursor-pointer'}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' }) || setSidebarOpened(false)}>
            <img src={'/static/logo.svg'}
                 width={'210px'}
                 height={'67px'}
                 className={'block 3xl:w-52 w-28 lg:w-32 xl:w-36 invert mt-0.5 xl:mt-1 4xl:mt-3 duration-1000 py-3 relative z-50'}
                 alt={'AGA ADK'}/>
          </Link>
        </div>

        <div
          className={'relative  backdrop-blusr-lg items-center shadosw hidden select-none xl:flex text-xs 3xl:text-sm 4xl:text-lg duration-700 font-light ' +
            (navSize) + ' ' +
            (window.location.pathname === '/' ? ' text-2xl' : '')}>
          <NavButton icon={faFileContract} title={'About'}
                     href={'/#about'}
                     onClick={() => document.getElementById('about')?.scrollIntoView({
                       behavior: 'smooth',
                       block: 'start'
                     })}
          />
          <NavButton icon={faFileContract} title={'Our Work'}
                     href={'/#work'}
                     onClick={() => document.getElementById('work')?.scrollIntoView({
                       behavior: 'smooth',
                       block: 'center'
                     })}/>
          <NavButton icon={faFileContract} title={'Clients'}
                     href={'/#clients'}
                     onClick={() => document.getElementById('clients')?.scrollIntoView({
                       behavior: 'smooth',
                       block: 'center'
                     })}/>
          <NavButton icon={faFileContract} title={'Team'}
                     href={'/#team'}
                     onClick={() => document.getElementById('team')?.scrollIntoView({ behavior: 'smooth' })}/>
          <NavButton icon={faFileContract} title={'News'}
                     href={'/#news'}
                     onClick={() => document.getElementById('news')?.scrollIntoView({ behavior: 'smooth' })}/>
          <NavButton icon={faFileContract} title={'Career'}
                     href={'/#careers'}
                     onClick={() => document.getElementById('careers')?.scrollIntoView({ behavior: 'smooth' })}/>
          <NavButton icon={faFileContract} title={'Contact Us'}
                     href={'#contact'}
                     onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}/>

        </div>

      </nav>
    </div>
    <FontAwesomeIcon
      className={'fixed xl:hidden focus-within:!outline-0 focus:!ring-none focus-visible:!ring-0 ring-0 active:!border-0 ' +
        ' z-[103] mr-5 dxl:mt-2.5 aspect-square duration-300 ring cursor-pointer ' +
        (sidebarOpened
          ? ' top-2 right-1.5 -rotate-90 text-xl p-4 rounded-3xl scale-75 shadow-xl ring-2 ring-sky-200 '
          : ' top-5 right-1.5 hover:pr-3 rounded-2xl hover:bg-white/10 hover:text-sky-300 p-3 -m-3 ring-transparent' +
          ' sm:text-3xl lg:text-3xl xl:text-3xl text-2xl')}
      tabIndex={1}
      onClick={() => setSidebarOpened(!sidebarOpened)}
      icon={sidebarOpened ? faMultiply : faBars}/>
    <Transition show={!!sidebarOpened}
                enter={'duration-200 '}
                enterFrom={'opacity-0 -mt-[100vh]'}
                enterTo={'opacity-100 mt-0'}
                leave={'duration-200'}
                leaveFrom={'-mr-[100vw] -top-0 opacity-100'}
                beforeEnter={() => setSidebarOpened(true)}
                beforeLeave={() => setSidebarOpened(false)}
                leaveTo={'-mr-[100vw] opacity-0'}>
      <div
        className={'max-h-screen overflow-y-scroll py-64 fixed z-[100] font-semibold flex items-center top-0 right-0 bg-black/90 backdrop-blur-2xl ' +
          'dark:text-white sm:max-w-sm max-w-lg w-full min-h-screen'} ref={sidebarRef}>
        <div className={'text-sm place-content-around w-screen min-h-[800px]'}>
          <div className={'w-min mx-auto text-xl overflow-hidden flex flex-wrap h-full contents-around'}>
            <div className={'w-full content-end'}>

              <SidebarMenuLink className={'w-min px-5 aspect-square items-center flex'}
                               text={<FontAwesomeIcon icon={faHome}/>}
                               target={'main'}/>

              <SidebarMenuLink text={'About'} target={'about'} href={'/#about'}/>
              <SidebarMenuLink text={'Work'} target={'work'} href={'/#work'}/>
              <SidebarMenuLink text={'Clients'} target={'clients'} href={'/#clients'}/>
              <SidebarMenuLink text={'Our Team'} target={'team'} href={'/#team'}/>
              <SidebarMenuLink text={'News'} target={'news'} href={'/#news'}/>
              <SidebarMenuLink text={'Career'} target={'careers'} href={'/#careers'}/>
              <SidebarMenuLink text={'Contact'} target={'contact'} href={'#contact'}/>
            </div>
            <div className={'w-full text-xl flex mt-10 items-center justify-center'}>
              <Link to={'https://www.facebook.com/AGAADK'}
                    target={'_blank'}
                    className={'hover:scale-110 p-5 transition-all cursor-pointer'}>
                <FontAwesomeIcon
                  icon={faFacebookSquare}/></Link>
              <Link to={'https://www.instagram.com/agaadkmena'}
                    target={'_blank'}
                    className={'hover:scale-110 p-5 transition-all cursor-pointer'}>
                <FontAwesomeIcon
                  icon={faInstagram}/></Link>
              <Link to={'https://www.linkedin.com/company/aga-adk-advertising-&-marketing/'}
                    target={'_blank'}
                    className={'hover:scale-110 p-5 transition-all cursor-pointer'}>
                <FontAwesomeIcon
                  icon={faLinkedin}/></Link>
              <Link to={'https://www.youtube.com/@aga-adk1383'}
                    target={'_blank'}
                    className={'hover:scale-110 p-5 transition-all cursor-pointer'}>
                <FontAwesomeIcon
                  icon={faYoutubeSquare}/></Link>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
}
